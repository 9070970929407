import "./Project.css";
import { PROJECTS } from "../../Config/PROJECTS";

import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

import parse from 'html-react-parser';

// images
import ThreeDBookCover from '../../Images/Projects/3d-book-animation/cover.webp';
import RRSImage from '../../Images/Projects/Reservation-System/homepage.webp';
import ProjectManagementSystem from '../../Images/Projects/Project-Management-System/Project-Management-System.webp'
import DPST from '../../Images/Projects/Dota-2-Personal-Stat-Tracker/DPST.webp'
import ReciPlanner from '../../Images/Projects/Reci-Planner/Reci-Planner.webp'
import LymliveInstaller from '../../Images/Projects/lymlive-media-installer/Lymlive-install-splash.png';


import LymliveVideo from '../../Assets/Lymlive_video.mp4'

function Project(props) {

    const project = PROJECTS[props.projectName];

    var projectName = "NOT FOUND!";
    var projectImage = "NOT FOUND!";
    var projectTags = "NOT FOUND!";
    var projectDesc = "NOT FOUND!";
    var projectLink;
    var projectTech = "NOT FOUND!";
    var projectClient = "NOT FOUND!";

    if (project != null) {

        projectName = PROJECTS[props.projectName].name;
        projectImage = PROJECTS[props.projectName].image;
        projectTags = PROJECTS[props.projectName].tags.join(' | ');
        projectDesc = PROJECTS[props.projectName].description;
        projectLink = PROJECTS[props.projectName].link;
        projectTech = PROJECTS[props.projectName].technology;
        projectClient = PROJECTS[props.projectName].client;
    }


    const targetDivRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        // Scroll to the target div when the location changes
        if (targetDivRef.current) {
        targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);


    return(

        <section className="project-panel"  ref={targetDivRef}>

            {projectName ? (<div className="project-information">
                    <div className="project-title-container">
                        <h1 className="title">{projectName}</h1>
                        <h2 className="sub-heading">{projectTags}</h2>
                        <div className="split-container">
                            <div>
                                <p className="project-description">{projectDesc}</p>
                            </div>
                            <div>
                                <div className="project-tag-style">
                                    <p style={{fontWeight: "bold"}}>Client</p>
                                    <p style={{textDecoration: "underline"}}>{projectClient}</p>
                                </div>
                                <div className="project-tag-style">
                                    <p style={{fontWeight: "bold"}}>Technology</p>
                                    <p style={{textDecoration: "underline"}}>{projectTech}</p>
                                </div>
                                <div>
                                <p></p>

                                </div>
                                
                            </div>
                        </div>
                        {projectLink ? (
                        <div style={{display: "block"}}>
                            <h2><a href={projectLink}>Open project</a></h2>
                        </div>
                        ): (<></>)}
                    </div>

                    <div>
                        {project.content != null && (
                            <div>{parse(project.content.jsx)}</div>
                        )}
                    </div>

                    <img alt="" src={projectImage} width={"80%"}/>
                

                </div>
                ) : (
                    <h1 className="title">PROJECT NOT FOUND!</h1>
                )
            }
        </section>

    );

}
export default Project;


