import "./RotatorPreview.css";

function RotatorPreview(props) {

    let height = '140px;'
    let width = 'auto';
    if (props.wh != null) {
        height = props.wh;
        width = props.wh;
    }

    return(
        <div className="rotatorPreview">

            {props.orient ? (
            <div className="rp-left">
                <img className="rp-image" alt="" src={props.img} height={height} width={width}></img>
                <div>
                <div className="rp-extender">
                    <span className="chevron right"></span>
                </div>
                </div>
            </div>
            ):(
            <div className="rp-right">
                <img className="rp-image" alt="" src={props.img} height={height} width={width}></img>
                <div className="rp-extender">
                    <span className="chevron right"></span>
                </div>
            </div>
            )}

        </div>

    );

}
export default RotatorPreview;