import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header.js';
import Footer from './Components/Footer/Footer.js';
import {Route, Switch, useParams} from 'react-router-dom';
import Home from './Pages/Home/Home.js';
import About from './Pages/About/About.js';
import Contact from './Pages/Contact/Contact.js';
import Projects from './Pages/Projects/Projects.js';
import Services from './Pages/Services/Services';
import Electronics from './Pages/Electronics/Electronics';
import LinkBar from './Components/LinkBar/LinkBar';
import NavBar from './Components/NavBar/NavBar';
import NavBarCard from './Components/NavBarCard/NavBarCard.js';
import Project from './Components/Project/Project.js';

function App() {

    var maintenanceMode = false;
    const { projectName } = useParams();

    return (
        <div className="App">
            {maintenanceMode ? (

            <div className='maintenance'>
                <div class="container">
                    <h1>Under Maintenance</h1>
                    <p>This website is currently undergoing maintenance. Will be back online shortly.</p>
                    <div class="contact-info">
                        <p>If you have any urgent matters, you can contact me at:</p>
                        <p>Email: mathewjenik1@gmail.com</p>
                    </div>
                </div>
            </div> 

        ) : (

            <div>
                {
                //<Header />
                
                }   
                <div className="main-content">
                    <Switch>
                        <Route path="/" exact><Home /><Footer /></Route>
                        <Route path="/about"exact><About /><Footer /></Route>
                        <Route path="/projects"exact><Header /><Projects /><Footer /></Route>
                        <Route path="/project/:projectName" exact><Home projectName={projectName}/><Footer /></Route>
                        <Route path="/services"exact><Services /><Footer /></Route>
                        <Route path="/electronics"exact><Electronics /><Footer /></Route>
                        <Route path="/contact" exact><Contact /><Footer /></Route>
                        <Route>Page Not Found!</Route>
                    </Switch>
                    <LinkBar />
                    
                </div>
            </div>
        )}

    

        </div>
    );
}

export default App;
