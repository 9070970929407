import React, { useState } from 'react';
import './ProjectSearcher.css'; // Import the CSS file

import Project from '../Project/Project';
import ThreeDBookCover from '../../Images/Projects/3d-book-animation/cover.webp';
import LymliveApp from '../../Images/Projects/lymlive-media-installer/Lymlive-install-splash.png';
import LifeCalander from '../../Images/Projects/life-calander/cover.webp';
import FBML from '../../Images/Projects/Bird-AI/Gen46.gif';
import PanVid from '../../Images/Projects/Panopto-video-downloader/application.PNG';

import Tameera_Numerology from '../../Images/Testimonials/Numerology-Calculator/Tameera-Numerology-Calculator.PNG';
import ProjectManagementSystem from '../../Images/Projects/Project-Management-System/Project-Management-System.webp'
import DotaPersonalStatTracker from '../../Images/Projects/Dota-Personal-Stat-Tracker/DPST.webp'


import LymliveVideo from '../../Assets/Lymlive_video.mp4'
import PopupModal from '../PopupModal/PopupModal';
import ProjectPreview from '../ProjectPreview/ProjectPreview';

import LymliveInstaller from '../../Images/Projects/lymlive-media-installer/Lymlive-install-splash.png';

const projects = [
    { name: 'Lymlive Installers Application', tags: ['React Native', 'Mobile'], desc: 'A mobile application made for lymlive media to allow for their installers to access their LCD panels and more, allowing configuration and setup from a mobile device.', img: LymliveApp},
    { name: 'Dota 2 Personal Stat Tracker', tags: ['React', 'Web'], desc: 'A website used to keep track of personal stats of the game Dota 2, showing important data such as hero matchups, hero winrates, game GPM/XPM and more.', img: DotaPersonalStatTracker},
    { name: 'Project Management System', tags: ['ASP.NET', 'Web'], desc: 'A website that keeps track of crypto prices and trains a nueral network on when to buy and sell crypto.', img:ProjectManagementSystem},
    { name: 'Restaurant Reservation System', tags: ['ASP.NET', 'Web'], desc: 'A Website made for a restuarant to take reservations, manage tables, bookings and view a graph of useful information.' },
    { name: 'Life Calender', tags: ['Angular', 'Web'], desc: 'A small web project to expeirment with some of the base features of Angular, this website simply shows a yearly calander with each average milstone being presented.', img: LifeCalander},
    { name: 'Panopto Lecture Video Downloader', tags: ['Desktop', 'Web'], desc: 'A console application that scrapes the Panopto video hosting service and downloads the individual stream segments and stitching the lecture together.', img: PanVid},
    { name: 'Flappy Bird AI Clone', tags: ['Machine Learning', 'Web'], desc: 'The basics of the game flappy bird cloned, with a generative algorithm used to train machine learning models.', img: FBML},
    { name: 'Online Numerology Calculator', tags: ['Web'], desc: 'Online Calculator that is used to provide a numerology reading as per the clients requirements.', img: Tameera_Numerology},
    { name: 'Online Book CSS Animation', tags: ['Web'], desc: 'CSS Book animation created to be used online for a twitch stream, created as per clients requirements.', img: ThreeDBookCover},
    
  ];

const ProjectSearcher = () => {
    const [selectedTag, setSelectedTag] = useState('');
    
    const handleTagClick = (tag) => {
      setSelectedTag(tag);
    };
    
    const filteredProjects = selectedTag ? projects.filter(project =>
      project.tags.includes(selectedTag)
    ) : projects;
  
    return (
        <div className="project-search-container">
            <div className="project-tags">
                <button className={`project-tag ${selectedTag === '' && 'active'}`} onClick={() => handleTagClick('')}>All</button>
                <button className={`project-tag ${selectedTag === 'React' && 'active'}`} onClick={() => handleTagClick('React')}>React</button>
                <button className={`project-tag ${selectedTag === 'Angular' && 'active'}`} onClick={() => handleTagClick('Angular')}>Angular</button>
                <button className={`project-tag ${selectedTag === 'ASP.NET' && 'active'}`} onClick={() => handleTagClick('ASP.NET')}>ASP.NET</button>
                <button className={`project-tag ${selectedTag === 'Web' && 'active'}`} onClick={() => handleTagClick('Web')}>Web</button>
                <button className={`project-tag ${selectedTag === 'Mobile' && 'active'}`} onClick={() => handleTagClick('Mobile')}>Mobile</button>
                <button className={`project-tag ${selectedTag === 'Desktop' && 'active'}`} onClick={() => handleTagClick('Desktop')}>Desktop</button>
                <button className={`project-tag ${selectedTag === 'React Native' && 'active'}`} onClick={() => handleTagClick('React Native')}>React Native</button>
            </div>

            <ul className="project-list">
                {filteredProjects.map((project, index) => (
                    <>
                    {/*
                    <PopupModal buttonText={"View More"} buttonOn={false} displayChildrenAsInner={false} innerContent={<div className='modal-popup-content'>
                      <h1>Lymlive Media Installers Application</h1>
                      <p>This application was completed throughout my final semester of university, where i worked with a client: Lymlive Media, to create this mobile application.

                      </p>
                      <h3>Video Showcase:</h3>
                      <video width="200" height="500" controls>
                        <source src={LymliveVideo} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                      <p>This video, showcases a general use case of the application. The application features user authentification, a simple design based off the clients instructions and feedback</p>
                    </div>}>
                    <Project key={index} tags={project.tags} name={project.name} desc={project.desc} img={project.img} />  
                    </PopupModal>
                    */}
                    <Project key={index} tags={project.tags} name={project.name} desc={project.desc} img={project.img} />  
                    
                    </>
                ))}
            </ul>
      </div>
    );
  };

export default ProjectSearcher;