import Testimonial from '../../Components/Testimonial/Testimonial.js';
import Project from '../../Components/Project/Project.js';
import './Projects.css';

// Images
import Big_Man_Compiler_Image from '../../Images/Projects/big-man-compiler/bmc-image2.webp';
import BirdAIImage from '../../Images/Projects/Bird-AI/Gen46.gif';
import RRSImage from '../../Images/Projects/Reservation-System/homepage.webp';
import Tameera_Numerology from '../../Images/Testimonials/Numerology-Calculator/Tameera-Numerology-Calculator.PNG';
import WinForms_Text_Editor from '../../Images/Projects/text-editor/TextEditorLI.webp';
import WinForms_Book_Database from '../../Images/Projects/book-database/BookDatabase.webp';
import YouTube_View_Bot_Image from '../../Images/Projects/Youtube-view-bot/youtube-view-bot.webp';
import Instragram_DMWB from '../../Images/Projects/drink-more-water-bot/water bottle.webp';
import Shortcut_Logo from '../../Images/Projects/shortcuts/shortcut-logo.webp';
import ThreeDBookCover from '../../Images/Projects/3d-book-animation/cover.webp';
import PanoptoThumbnail from '../../Images/Projects/Panopto-video-downloader/application.PNG';
import LifeCalanderCover from '../../Images/Projects/life-calander/cover.webp';
import ProjectSearcher from '../../Components/ProjectSearcher/ProjectSearcher.js';

function Projects() {
    return(
        <section className="projects-content snap">
            <h2>Projects</h2>
            <div className="projects-container">
                {//<Project name={'Dota 2 Stat and Tournament tracker'} tagString={'React'} desc={''} img ={''} />
                }

                <ProjectSearcher></ProjectSearcher>
                
                {/* 
                <Project name={'Dota 2 Personal Stat Tracker'} tagString={'MongoDB, Express, React, Node'} desc={'A website that keeps track of crypto prices and trains a nueral network on when to buy and sell crypto.'} img={ThreeDBookCover} />
                <Project name={'Project Management System'} tagString={'ASP.NET'} desc={'A website that keeps track of crypto prices and trains a nueral network on when to buy and sell crypto.'} img={ThreeDBookCover} />
                
                <Project name={'AI Crypto Trading Website (WIP)'} tagString={'JS,Nueral Networks'} desc={'A website that keeps track of crypto prices and trains a nueral network on when to buy and sell crypto.'} img={ThreeDBookCover} />
                <Project name={'University/Panopto Video Downloader'} tagString={'C++,FFMPEG,Multithreading'} desc={'After having university lectures becomes innaccessible for offline storage, through panopto, i created a program that automattically scrapes the entire lecture and saves it locally, bypassing panoptos download prevention measures.'} wh={'140px'} img={PanoptoThumbnail}/>
                <Project name={'Life Calander'} tagString={'Angular,JS,CSS'} desc={'This web application shows the average achievements people complete in their lifetime, this project was used to learn angular.'} links={[{name: 'LifeCalander', url: 'https://lifecalendar-f23d0.web.app'}]}  img={LifeCalanderCover} />
                <Project name={'3D Book Animation'} tagString={'HTML,CSS,Freelance'} desc={'A project completed that shows a 3D book animation opening and closing, with two differing versions.'} links={[{name: 'Version A', url: 'https://mathewjenik.github.io/3d-book-animation/'},{name: 'Version B', url: 'https://mathewjenik.github.io/3d-book-animation/Ver%20B/'}]} img={ThreeDBookCover} />
                <Project name={'Automated Text Sender'} tagString={'iOS,Shortcuts'} desc={'A script developed on iOS Shortcuts, This allows a person to send automated text messages as well as pick custom messages and even pick from a selection of options radomly.'} img={Shortcut_Logo} />
                <Project name={'Drink More Water Bot'} tagString={'Instagram, Bot, Node.JS'} desc={'An instagram bot that is dedicated to sending Direct Messages to users to remind them to drink water.'} img={Instragram_DMWB} />
                <Project name={'BM Compiler (WIP)'} tagString={'C++,Compiler'} desc={'A custom programming language and compiler created to gain a better understanding of compilers.'} img={Big_Man_Compiler_Image}/>
                <Project name={'YouTube View Bot'} tagString={'C#,Windows Forms'} desc={'A custom made YouTube View bot made for a YouTuber, this project utilises multithreading to speed up the process with custom inputs and a user friendly UI.'} img={YouTube_View_Bot_Image} />
                <Project name={'Flappy Bird AI'} tagString={'Java, Machine Learning'} desc={'Machine Learning project which utilises a clone of the game Flappy Bird i made within javascript. It shows the learning process the AI/Genetic Algorithm completes, through 46 generations.'} img={BirdAIImage} />
                <Project name={'Restuarant Reservation System'} tagString={'C#,.NET,EFCORE,SQL,CSS'} desc={'Restuarant Reservation System to allow for a restuarant to make reservations, events, track reporting data and more.'} img={RRSImage} wh={'140px'} />
                <Project name={'Windows Forms Text Editor'} tagString={'C#,WinForms'} desc={'A Text Editor made using Windows Forms in C#, this was a task i completed in my further educations because i had commonly finished the assigned work early and was given extra work.'} img={WinForms_Text_Editor} />
                <Project name={'Windows Forms Book Database'} tagString={'C#,WinForms,SQL'} desc={'A Windows Forms Application that allows for books to be added/deleted/found/changed within a book database.'} img={WinForms_Book_Database} />

                */}

                
            </div>
            <h2>Testimonials:</h2>
            <h4>Hover to view more.</h4>
            <Testimonial name={'Rebecca L'} stars={5} img={ThreeDBookCover} link={'https://mathewjenik.github.io/3d-book-animation/'} desc={'"Thank you Mathew for undertaking this project and for your responive and quick replies. Truly amazing you were able to integrate my ideas within your skills of coding. Thank you for accepting this project and for your time :)"'} />
            <Testimonial name={'Tameera'} stars={5} img={Tameera_Numerology} link={'https://tameera.com/free-numerology-name-calculator/'} desc={'"Mathew is an absolute STAR! Incredible communication, high attention to detail and went above and beyond to make my new Wordpress software calculation tool a success. I could not be happier and will absolutely work with Mathew again. He was kind, patient and delivered my job in super fast time. One of the best if not the best ive worked with! Nothing was too much trouble. He used CRUD methodolgies to allow for me to edit any of the descirption when needed as well as updating pages of my current Wordpress website to feature links to the newly created page that will host my niche calculation tool. He also allowed for the page to be mobile friendly, resposnive, user friendly and work on all platforms, through CSS and design. Thank you so much Mathew you are THE BEST :)"'} />
        </section>
    );
}
export default Projects;
