import React, { useState, useEffect } from 'react';
import './NavBarCard.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareDown } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
import _ from 'lodash';

function NavBarCard() {
    const [title, setTitle] = useState('Mathew Jenik');
    const [open, setOpen] = useState('false');

    const [smallWindow, setSmallWindow] = useState(false);

    useEffect(() => {
      const handleResize = _.debounce(() => {
        setSmallWindow(window.innerWidth < 750);
      }, 100); // Debounce for 100 milliseconds
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    function openNav() {
        if (open == "false") {
            setOpen("true");
            document.getElementById('nav-bar-card-desktop').style.display = "block";
            document.getElementById('nav-bar-card-desktop').className = "nav-bar-card-mobile-open";
        } else {
            setOpen("false");
            document.getElementById('nav-bar-card-desktop').style.display = "none";
            document.getElementById('nav-bar-card-desktop').className = "nav-bar-card-desktop";

        }


    }

    function navigateToPage(loc) {
        window.location.href=loc;
    }

    return (
        <div id="nav-bar-card" className="nav-bar-card-container">
        {/*<h1><a href="/">{title}</a></h1>*/}
        {
        //<button onClick={openNav} className="nav-bar-card-mobile"><FontAwesomeIcon icon={faCaretSquareDown} color="#AAAAAA" /></button>
        }
        
        <button onClick={openNav} className="nav-bar-card-mobile">Menu</button>
        

        {setOpen && (
            
            <ul id="nav-bar-card-desktop" className="nav-bar-card-desktop">
                {/* using the 'style' then the custom property is used for animation offsets in css */}
                <li /*style={{marginTop:3+'em'}}*/ onClick={() => window.location.href="/"}><a>Home</a></li>
                <li /*style={{marginTop:3+'em'}}*/ onClick={() => window.location.href="/#home-content"}><a>About</a></li>
                <li /*style={{marginTop:4+'em'}}*/ onClick={() => window.location.href="/#featured-projects"}><a href="#projects">Featured</a></li>
                {/*<li onClick={() => window.location.href="/services"}><a href="/services">Services</a></li>*/}
                {/*<li onClick={() => window.location.href="/electronics"}><a href="/electronics">electronics</a></li>*/}
                <li /*style={{marginTop:5+'em'}}*/ onClick={() => window.location.href="/contact"}><a href="/contact">Contact</a></li>
                {/*<li /*style={{marginTop:4+'em'}} onClick={() => window.location.href="/projects"}><a href="/projects">All Projects</a></li>*/}
                
                
            </ul>
        )}

        </div>
    );
}
export default NavBarCard;
