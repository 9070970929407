import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

import { useHistory } from 'react-router-dom';

import './PopupModal.css';

function PopupModal(props) {
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  
  const handleOpen = () => {
    if (props.navigate == true) {
      history.push(props.navigateTo);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    if (props.navigate == true) { 
      history.push(props.navigateTo);  
    } else {
      setOpen(false)
    }
  };

  return (
    <div className='popupModal-component'>
      

      <Button onClick={handleOpen}>{props.viewText}</Button>
      <div onClick={handleOpen}>
      {props.displayChildrenAsInner == false &&
        props.children 
      }
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        overflow='scroll'
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box className='popupModal'>

            {props.displayChildrenAsInner == false &&
              props.innerContent 
            }
            {props.displayChildrenAsInner == true &&
              props.children
            }
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
export default PopupModal;