import ThreeDBookCover from '../Images/Projects/3d-book-animation/cover.webp';
import RRSImage from '../Images/Projects/Reservation-System/homepage.webp';
import ProjectManagementSystem from '../Images/Projects/Project-Management-System/Project-Management-System.webp'
import DPST from '../Images/Projects/Dota-2-Personal-Stat-Tracker/DPST.webp'
import ReciPlanner from '../Images/Projects/Reci-Planner/Reci-Planner.webp'
import LymliveInstaller from '../Images/Projects/lymlive-media-installer/Lymlive-install-splash.png';

import LymliveVideo from '../Assets/Lymlive_video.mp4'

export const PROJECTS = {
    reciplanner: {
        name: "Reci-Planner",
        image: ReciPlanner,
        description: "A Web application used to store and distribute recipes. This website contains features such as liking recipes, uploading and viewing user made recipes and searching recipes based off ingredients you have available.",
        tags: ["ASP.NET","Web","JavaScript", "HTML"],
        client: "Mathew Jenik",
        technology: "ASP.NET",
        link: "http://apps.mathewjenik.com/rp",
    },
    dpst: {
        name: "Dota 2 Personal Stat Tracker",
        image: DPST,
        description: "A web application featured around showing personalised stats surrounding Dota 2. Featuring win rates, average game rating and more.",
        tags: ["MongoDB","Express", "Express", "React", "Node"],
        client: "Mathew Jenik",
        technology: "MERN",
        link: "http://apps.mathewjenik.com/dpst",
    },
    pms: {
        name: "Project Management System",
        image: ProjectManagementSystem,
        description: "A project management system, which utilises a kanban board like structure to allow easy planning of projects.",
        tags: ["ASP.NET","MVC","EFCORE"],
        client: "Mathew Jenik",
        technology: "ASP.NET",
        link: "http://apps.mathewjenik.com/pms",
    },
    installersapp: {
        name: "Lymlive Installers Application",
        image: LymliveInstaller,
        description: "A mobile application built in react native for Lymlive Media. This applicaiton is used for digital signage installation and setup. It allows employess to configure each device without needing to access the device directly, as some media players are stored in innaccessable areas.",
        client: "Lymlive Media",
        technology: "React Native",
        tags: ["React Native","Mobile","TypeScript"],
        content: {
            jsx: `
            <video width="200" height="500" controls>
            
                <source src=${LymliveVideo} type="video/mp4"/>
                Your browser does not support HTML video.
                </video>
            <p>This video, showcases a general use case of the application. The application features user authentification, a simple design based off the clients instructions and feedback</p>
        
            <div>
                <h2>Key Features:</h2>
                <div>Communicates to Clients Server</div>
                <div>Shows vital information</div>
                <div>Allows ease of access to media player settings</div>
            </div>
            `
        }
    }
     
}