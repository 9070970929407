import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';


import './styles.css';
import Profile from '../../Images/Headshot photo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faMobile } from '@fortawesome/free-solid-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';

import ProjectPreview from '../../Components/ProjectPreview/ProjectPreview';
import BirdAI from '../../Images/Projects/Bird-AI/Gen46.gif';
import Canvas from '../../Components/GameOfLifeAnimation/GameOfLifeAnimation';
import Rotator from '../../Components/Rotator/Rotator';
import RotatorPreview from '../../Components/Rotator/RotatorPreview.js';
import StyleComp from '../../Images/Stylised computer.jpg';
import LymliveInstaller from '../../Images/Projects/lymlive-media-installer/Lymlive-install-splash.png';
import PopupModal from '../../Components/PopupModal/PopupModal.js';
import NavBarCard from '../../Components/NavBarCard/NavBarCard.js';

import Project from '../../Components/Project/Project.js';
// Images
import ThreeDBookCover from '../../Images/Projects/3d-book-animation/cover.webp';
import RRSImage from '../../Images/Projects/Reservation-System/homepage.webp';
import ShufflingText from '../../Components/ShufflingText/ShufflingText.js';
import Profile1Image from '../../Images/Headshot photo.webp';
import ProjectManagementSystem from '../../Images/Projects/Project-Management-System/Project-Management-System.webp'
import DPST from '../../Images/Projects/Dota-2-Personal-Stat-Tracker/DPST.webp'
import ReciPlanner from '../../Images/Projects/Reci-Planner/Reci-Planner.webp'

// Video
import LymliveVideo from '../../Assets/Lymlive_video.mp4'
import ContentBox from '../../Components/ContentBox/ContentBox.js';
import ProjectSearcher from '../../Components/ProjectSearcher/ProjectSearcher.js';
import WorkExperienceCard from '../../Components/WorkExperienceCard/WorkExperienceCard.js';
import FeaturedPreview from '../../Components/FeaturedPreview/FeaturedPreview.js';


import Spline from '@splinetool/react-spline';
import ModelLoader from '../../Components/ModelLoader/ModelLoader.js';
import ProjectTab from '../../Components/ProjectTab/ProjectTab.js';
function Home() {

    
    const { projectName } = useParams();

    function GetScrollY() {
        
        window.onscroll = function(event) {
            //
            // console.log("SCHS");
            //console.log("ScrollY: " + window.scrollY);
            var scrollPos = window.scrollY;

            if (!projectName) {
                    
                // expertise spawn animation
                if (scrollPos > 500) {

                }

                // profile spawn animation
                if (window.scrollY > 1000) {
                    
                    document.getElementById("focus-image").className="focus-image-animated";
                    document.getElementById("profile-picture").className="profile-picture-animated col-left";
                    document.getElementById("profile-text").className="profile-text-animated col-right";
                    document.getElementById("focus-image").className="focus-image-animated";

                // 
                    
                }

                if (scrollPos > window.innerHeight) {
                    document.getElementById("nav-bar-card").className="nav-bar-card-container-expanded";    
                } else {
                    document.getElementById("nav-bar-card").className="nav-bar-card-container";    
                }
    
            }

            
        };
    
    }
    GetScrollY();

    function showData() {
        //console.log(document.getElementById("headshot").getBoundingClientRect().top);
    }
    // distance of 500

    function shuffleText() {
        var elem = document.getElementsByName("shuffling-text");
        
    }

        
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [error, setError] = useState(null);

    //

    // Add this useEffect to handle the scroll event interception
    useEffect(() => {
        const overlay = document.querySelector('.spline-overlay');
        if (overlay) {
            overlay.addEventListener('wheel', (event) => {
                event.preventDefault();
            }, { passive: false });
        }

        return () => {
            if (overlay) {
                overlay.removeEventListener('wheel', (event) => {
                    event.preventDefault();
                });
            }
        };
    }, []);

    // welcome timing fadeout
    const [loading, setLoading] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setFadeOut(true);
          setTimeout(() => {
            setLoading(false);
          }, 500); // duration of the fade-out animation
        }, 1500); // 1.5 seconds
    
        return () => clearTimeout(timer);
      }, []);


    return(
        <div className="page">
            
            {loading && (
                <div className={`loading-screen ${fadeOut ? 'fade-out' : ''}`}>
                    <h1>Welcome</h1>
                </div>
            )}

            <section className='landing-container'>
                <section className="landing snap">

                    <div className="spline-overlay"></div>
                    <iframe 
                    id='landing-3d'
                    src='https://my.spline.design/macbookprocopy-a0118094eb911326b0974667a6878c87/'
                    frameborder='0'
                    width='100%'
                    height='100%'
                    scrolling='no'
                    style={{ overflow: 'hidden' }}
                    ></iframe>
                    
                    
                    <div className="intro">
                        {/*<h2 id="shuffling-text" defaultValue={"shuffleText"}>Mathew Jenik</h2>*/}
                        <ShufflingText tag={"h2"} original={"Mathew Jenik"} on={false}/>
                        
                        {/*
                        <Rotator orient={true} elems={
                        [<RotatorPreview img={RRSImage} />, <RotatorPreview img={RRSImage} />, <RotatorPreview img={RRSImage} />,
                        <RotatorPreview img={RRSImage} />, <RotatorPreview img={RRSImage} />, <RotatorPreview img={RRSImage} />]
                        }/>
                        */
                        }


                        <article className="sweeping-text-wrapper">
                            {/*<h4>Freelance Software Developer</h4>*/}
                            <div className="sweeping-text-container">
                                <div className="sweeping-text">
                                    <h4>Software Developer</h4>
                                    <h4>Web Developer</h4>
                                </div>

                            </div>
                        </article>
                        <div class="scroll-down-animation">
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                            <span class="text-pulse">Scroll down</span>
                        </div>
                        
                    </div>
                </section>
            </section>

            <NavBarCard />




            {// Everything after here is the conditional for projects
            }

                        
            {projectName ? (
                <div className='page'>
                    <Project projectName={projectName}/>
                </div>
            ) : (

            <div>

                <section id="experience-content" className="experience-panel snap">
                    <article>
                        <h1 className='title'>Expertise</h1>
                        <div>
                            <ContentBox icon={<FontAwesomeIcon icon={faLaptop} style={{color: "#FFFFFF",}} />} heading={"Software Development"} text={"General software development, using technologies such as windows forms, console applications, game interfaces, Java GUI and more."}/>
                            <ContentBox icon={<FontAwesomeIcon icon={faReact} />} heading={"React.js + Full Stack Web"} text={"Wide experience in a variety of technologies such as React.js, base HTML, CSS and JS, Angular, ASP.NET."}/>
                            <ContentBox icon={<FontAwesomeIcon icon={faMobileAlt} />} heading={"Mobile App Development"} text={"Skilled in developing mobile apps both natively and using React Native, allowing for cross platform solutions."}/>
                        </div>
                    </article>
                </section>

                <section className='work-exp-section snap'>
                    <article>
                        <h1>Work Experience</h1>
                    </article>
                        
                    <WorkExperienceCard
                        company="Nexgen Codecamp"
                        position="Software Engineer + Server Admin"
                        startDate="Jan 2022"
                        endDate="Dec 2023"
                        description="Responsible for maintaining the Nexgen server running software for different schools and teaching programs. Developing web applications and instructing classes on areas of programming and robotics."
                        />

                    <WorkExperienceCard
                        company="Mathew Jenik"
                        position="Freelance Software Engineer"
                        startDate="Jan 2021"
                        endDate="Present"
                        description="Software and Website developer working closely with clients to develop custom projects to suit the clients needs."
                        />

                    <WorkExperienceCard
                        company="WSU OOP Unit"
                        position="Volunteer Marker"
                        startDate="July 2021"
                        endDate="November 2021"
                        description="Responsible for marking university students weekly class work, providing feedback and solutions to those that havent completed the work required."
                        />

                </section>
                
                <section id="home-content" className="home-content snap">
                    {//<Canvas/>
                    }
                    <article id="home-hero" className="columns image-statement">
                            <div id="profile-picture" className="profile-picture col-left">
                                <div className="focus-image-container">
                                    <img id="focus-image" className="focus-image" src={Profile} />
                                </div>
                            </div>
                            <div id="profile-text" className="profile-text col-right" onClick={() => {showData()}}>
                                <div>
                                    <h1>Hey!</h1>
                                    <i>Country Boy Turned IT</i><br/>
                                    <p>My name is Mathew Jenik<br/>
                                    Bachelor of Computer Science Graduate (Majored in Artificial Intelligence).<br/>
                                    I am a website and software developer, with a variety of experience in varying technologies.</p>
                                </div>
                                
                            </div>

                    

                    </article>

                    <article>
                        <section className='text-section'>
                            <div id="follow-up-info">
                                <p>I have worked with a variety of differing frameworks, languages and libraries, some of which include LibGDX, .NET Core, GLFW, LWJGL and many more.<br/>
                                My Projects and general software applications can be found on my <a href="https://github.com/MathewJenik"> GitHub <FontAwesomeIcon icon={faGithubSquare} /> </a> and my <a href="https://www.linkedin.com/in/mathew-jenik-0895b8198/?originalSubdomain=au"> LinkedIn <FontAwesomeIcon icon={faLinkedin} /> </a>

                                </p>
                            </div>
                        </section>
                    </article>

                </section>

                

                <section id="featured-projects" className="featured-projects snap">
                    <div className='fixed-background'>
                        <div className='featured-projects-gradient'>
                            <section>
                                <h1>Featured Projects:</h1>
                                
                            </section>
                            {
                                <div className='section-content'>
                                {
                                //<h1 className="titles">Featured Projects</h1>
                                }
                                <article className='projects'>
                                    
                                    <div className='project-preview-container'>

                                        <PopupModal buttonText={"View More"} buttonOn={false} displayChildrenAsInner={false} navigate={true} navigateTo={'/project/reciplanner'} innerContent={
                                            <div className='modal-popup-content'>
                                                <h1>Recipe Planner</h1>
                                                <p>This application was completed throughout my final semester of university, where i worked with a client: Lymlive Media, to create this mobile application.

                                                </p>

                                                <div>
                                                    <h2>Key Features:</h2>
                                                    <div>Communicates to Clients Server</div>
                                                    <div>Shows vital information</div>
                                                    <div>Allows ease of access to media player settings</div>
                                                </div>

                                            </div>
                                        }>
                                            <FeaturedPreview name={"Reci-Planner"} desc={"A Web application used to store and distribute recipes."} img={ReciPlanner} tags={'ASP.NET,Web,Javascript,HTML'}/>
                                        </PopupModal>


                                        <PopupModal buttonText={"View More"} buttonOn={false} displayChildrenAsInner={false} navigate={true} navigateTo={'/project/installersapp'}  innerContent={
                                        <div className='modal-popup-content'>
                                            <h1>Lymlive Media Installers Application</h1>
                                            <p>This application was completed throughout my final semester of university, where i worked with a client: Lymlive Media, to create this mobile application.

                                            </p>
                                            <h2>Video Showcase:</h2>
                                            <video width="200" height="500" controls>
                                            <source src={LymliveVideo} type="video/mp4"/>
                                            Your browser does not support HTML video.
                                            </video>
                                            <p>This video, showcases a general use case of the application. The application features user authentification, a simple design based off the clients instructions and feedback</p>
                                        
                                            <div>
                                                <h2>Key Features:</h2>
                                                <div>Communicates to Clients Server</div>
                                                <div>Shows vital information</div>
                                                <div>Allows ease of access to media player settings</div>
                                            </div>

                                        </div>
                                        }>
                                            <FeaturedPreview name={"Lymlive Media Installers Application"} desc={"A mobile application built in react native for Lymlive Media. This applicaiton is used for digital signage installation."} img={LymliveInstaller} tags={'React Native,Mobile,TypeScript'}/>
                                        </PopupModal>

                                        <PopupModal buttonText={"View More"} buttonOn={false} displayChildrenAsInner={false} navigate={true} navigateTo={'/project/dpst'}  innerContent={
                                        
                                        <div className='modal-popup-content'>
                                            <h1>Dota 2 Personal Stat Tracker</h1>
                                            <p>This application is primarily made using React and Node. The purpose of this application is to show more personalised statistics for players within Dota 2.
                                                Dota 2 Personal Stat Tracker features recent game rank tracking, win rate, win rate on specific heroes, overal dota information and more.
                                            </p>
                                            <h3>Link: <a href="http://apps.mathewjenik.com/dpst">apps.mathewjenik.com/dpst</a></h3>
                                            
                                            
                                            <div className='key-features'>
                                                <h2>Key Features:</h2>
                                                <div>
                                                    <p>Statistic Tracking</p>
                                                    <p>Hero win rates</p>
                                                    <p>Hero matchups</p>
                                                    <p>General dota information</p>
                                                    <p>User login/auth.</p>
                                                </div>
                                            </div>

                                            <div className='tech-stack'>
                                                <h2>Tech Stack:</h2>
                                                <div>
                                                    <p>React</p>
                                                    <p>Node</p>
                                                    <p>MongoDB</p>
                                                    <p>Express</p>
                                                </div>
                                            </div>

                                        </div>
                                        }>
                                            <FeaturedPreview name={"Dota 2 Personal Stat Tracker"} desc={"A web application featured around showing personalised stats surrounding Dota 2. Featuring win rates, average game rating and more."} img={DPST} tags={'MongoDB, Express, React, Node'}/>
                                        </PopupModal>
                                        
                                        <PopupModal buttonText={"View More"} buttonOn={false} displayChildrenAsInner={false} navigate={true} navigateTo={'/project/pms'} innerContent={
                                        
                                        <div className='modal-popup-content'>
                                            <h1>Project Management System</h1>
                                            <p>This application is made using .NET and the knowledge i gained during my Diploma of software Development.
                                                I have since improved alot, and occasionally come back to the project to integrate a new feature or improve upon its design.
                                            </p>
                                            <h3>Link: <a href="http://apps.mathewjenik.com/pms">apps.mathewjenik.com/pms</a></h3>
                                            
                                            
                                            <div className='key-features'>
                                                <h2>Key Features:</h2>
                                                <div>
                                                    <p>Task Tracking</p>
                                                    <p>User Login + Auth</p>
                                                    <p>PostgreSQL Database</p>
                                                </div>
                                            </div>

                                        </div>
                                        }>
                                            <FeaturedPreview name={"Project Management System"} desc={"A project management system, which utilises a kanban board like structure to allow easy planning of projects."} img={ProjectManagementSystem} tags={'ASP.NET,MVC,EFCORE'}/>
                                        </PopupModal>
                                        
                                        
                                        
                                        {
                                        //<ProjectPreview name={"Dota 2 Personal Stat Tracker"} desc={"Personal Stat Tracker for the game Dota 2, this was made"} img={BirdAI} tags={'MongoDB,Express,React,Node'}/>
                                        }

                                        

                                    </div>
                                    <ProjectTab name={'Hi!'} tags={''} desc={'Want to View More of my Projects? Check out the Projects page!'} img={Profile1Image} />
                                </article>
                            </div>
                            
                            }
                        </div>
                    </div>
                </section>

                
            </div>
            )}
        </div>
    );

   
}
export default Home;
