import React, { useState } from 'react';
import './styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareDown } from '@fortawesome/free-solid-svg-icons';


function Header() {
    const [title, setTitle] = useState('Mathew Jenik');
    const [open, setOpen] = useState('false');

    function openNav() {
        if (open == "false") {
            setOpen("true");
            document.getElementById('nav-desktop').style.display = "block";
            document.getElementById('nav-desktop').className = "navigation-mobile-open";
        } else {
            setOpen("false");
            document.getElementById('nav-desktop').style.display = "none";
            document.getElementById('nav-desktop').className = "navigation-desktop";

        }

    }

    function navigateToPage(loc) {
        window.location.href=loc;
    }

    return (
        <div className="header">
        <h1><a href="/">{title}</a></h1>
        <ul id="nav-desktop" className="navigation-desktop">
            {/* using the 'style' then the custom property is used for animation offsets in css */}
            {/*<li /*style={{marginTop:3+'em'}} onClick={() => window.location.href="/about"}><a href="/about">About Me</a></li>*/}
            <li onClick={() => window.location.href="/"}><a href="/">Home</a></li>
            <li /*style={{marginTop:4+'em'}}*/ onClick={() => window.location.href="/projects"}><a href="/projects">Projects</a></li>
            {/*<li onClick={() => window.location.href="/services"}><a href="/services">Services</a></li>*/}
            {/*<li onClick={() => window.location.href="/electronics"}><a href="/electronics">electronics</a></li>*/}
            {/*<li /*style={{marginTop:5+'em'}} onClick={() => window.location.href="/contact"}><a href="/contact">Contact Me</a></li>*/}
        </ul>

        <button onClick={openNav} className="navigation-mobile"><FontAwesomeIcon icon={faCaretSquareDown} color="#AAAAAA" /></button>

        </div>
    );
}
export default Header;
