import './styles.css';

function Testimonial(props) {

    const starArray = ["fa fa-star","fa fa-star","fa fa-star","fa fa-star","fa fa-star"];
    function starSetup() {
        let stars = props.stars;
        for (var i = 0; i < stars; i++) {
            starArray[i] = "fa fa-star checked";
        }
    }

    starSetup();

    return(
        <section className="testimonial-container snap">
            <h4>{props.name}</h4>
            <span id="test1" className={starArray[0]}></span>
            <span id="test2" className={starArray[1]}></span>
            <span id="test3" className={starArray[2]}></span>
            <span id="test4" className={starArray[3]}></span>
            <span id="test5" className={starArray[4]}></span>
            <p><a href={props.link}>{props.link}</a></p>
            <p>{props.desc}</p>
            <a href={props.link}><img className="testimonial-image" alt="" src={props.img}/></a>

        </section>
    );
}
export default Testimonial;
