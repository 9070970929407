/**
 * @Author: Mathew Jenik
 * @Date:   2022-11-22 23:43:33
 * @Last Modified by:   Mathew Jenik
 * @Last Modified time: 2022-11-23 00:45:17
 */


function Qualification(props) {

    return(
        <li className="qualification-item">
            {props.name}
            <div className="qualification-item-value" width={props.value} >
            </div>
        </li>
    );

} export default Qualification;