/**
 * @Author: Mathew Jenik
 * @Date:   2021-12-02 12:13:46
 * @Last Modified by:   Mathew Jenik
 * @Last Modified time: 2022-11-23 00:30:58
 */
import './About.css';
import Profile from '../../Images/Headshot photo.webp';
import Qualification from '../../Components/Qualification/Qualification';

function About() {


    const qualificationList = [{ name: "Bachelor of Computer Science", value: "10" },
    { name: "Diploma of Software Development", value: "7" },
    { name: "Cert IV in Programming", value: "10" },
    { name: "Cert IV in IT", value: "10" },  
    ];

    
    return (
        <div className="about-container snap">
            <article className="columns image-statement">
                <div id="profile-picture" className="profile-picture col-left">
                    <div className="focus-image-container">
                        <img id="focus-image" className="focus-image-plain" src={Profile} />
                    </div>
                </div>
                <div id="profile-text" className="profile-text-plain col-right">
                    <h1>Hey!</h1>
                    <p>Welcome to my porfolio website!<br/>
                    I am a recent Bachelor of Computer Science Graduate (Majoring in Artificial Intelligence).<br/>
                    As well as an occasional freelance website and software developer.<br/></p>
                </div>

            </article>
        
            <h2>About Me</h2>

            <p>I am an aspiring software developer, with experience developing websites and desktop applications for 2 years. I was initially self
            taught, although now i have completed formal tertiary educational training. which includes my current enrollment as a second year university
            student completing my Bachelor of Computer Science (Majoring in Artificial Intelligence). I have developed a large amount of applications
            ranging from desktop applications, machine learning applications, websites and web applicaitons as well as many scripts and general programs for various needs.

            </p>

            <p className='qualifications'>
            The current qualifications i have completed are:
            </p>
            <ul>
            <Qualification name="Bachelor of Computer Science (Majoring in Artificial Intelligence)" value="10" />
            <Qualification name="Diploma of Software Development" value="8" />
            <Qualification name="Certificate IV in Programming" value="6" />
            <Qualification name="Certificate IV in IT" value="6" />
            </ul>
            {/*
            <ul className='qual-list'>
                <li>Bachelor of Computer Science (Majoring in Computer Science) - Graduating in 2023</li>
                <li>Diploma of Software Development - Graduated 31/06/2020</li>
                <li>Cert IV (certificate 4) in Programming - Graduated 18/11/2019</li>
                <li>Cert IV (certificate 4) int IT (Information Technology) - Graduated 31/06/2019</li>
            </ul>
            */}

            
            <p>I can code in the following languages and frameworks:</p>

            <ul>
                <li>C#</li>
                <li>HTML + CSS</li>
                <li>JavaScript</li>
                <li>PHP</li>
                <li>Java</li>
                <li>C++</li>
                <li>Python</li>
                <li>React + ReactNative</li>
                <li>And More</li>
            </ul>
        
        </div>
    );
}
export default About;
