import { render } from '@testing-library/react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import './FeaturedPreview.css';
import { useState } from 'react';


// big square, with image on top
function FeaturedPreview(props) {

    const [isHovered, setIsHovered] = useState(false)
    const [isLabelHovered, setIsLabelHovered] = useState(false)
    

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
    setIsHovered(false);
    };

    const handleMouseEnterLabel = () => {
        setIsLabelHovered(true);
    };

    const handleMouseLeaveLabel = () => {
        setIsLabelHovered(false);
    };
    

    function renderTags() {
        let tags = [];
        var splitTags = props.tags.split(",");
        console.log(splitTags);
        for (let i = 0; i < splitTags.length; i++) {
            tags.push(<p className='tag'>{splitTags[i]}</p>);
        }
        return tags;
    }

    return (
        <div className="FeaturedPreview-container">
            <div className={`FeaturedPreview-item${isLabelHovered ? '-hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className='image-container'>
                    <img src={props.img} /> 
                </div>
                <div className="FeaturedPreview-text-container">
                    <h3>{props.name}</h3>
                    
                    <div className='FeaturedPreview-tags'>
                        {renderTags()}
                    </div>
                    {
                        /*
                    
                    <p>
                        {props.desc}
                    </p>
                    */
                    }
                </div>
            </div>
            {(isLabelHovered || isHovered) && (
                    <div className="hover-content" onMouseEnter={handleMouseEnterLabel} onMouseLeave={handleMouseLeaveLabel}>
                        {/* Additional content to be rendered on hover */}
                        <p>View Project</p>
                    </div>
            )}

        </div>




        
    );
}

export default FeaturedPreview;