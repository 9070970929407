import React, { useState } from 'react';
import Project from '../../Components/Project/Project.js';
import './Contact.css';


// Images
//import Profile1Image from '../../Images/Profile-Downsized.jpeg';
import Profile1Image from '../../Images/Headshot photo.webp';

function Contact() {
    return (
        <section className="contact-content snap">
        <Project name={'Hi!'} tags={''} desc={'If there is anything you would like to know, dont hesitate to get in contact with me.'} img={Profile1Image} />
        <div className="contact-container">
            <div className="">
                <div className="">
                    <h2>Contact Me</h2>
                </div>
                <div className="">
                    <form method="POST" action="https://formspree.io/f/xnqlzjag" id="contact-form">
        					<div className="row">
        						<div className="contact-name">
        							<label for="name">Name</label>
        							<input type="text" name="name" id="name" required="required" data-error="Name is required."/>
        						</div>
        						<div className="contact-email">
        							<label for="email">Email</label>
        							<input type="email" name="email" id="email" required="required" data-error="Valid email is required."/>
        						</div>
                            </div>

                        	<div className="gridItem">
        						<label for="message">Message</label>
        						<textarea className="contact-messageArea" name="message" id="message" rows="4" required="required" data-error="Message is required."></textarea>
        					</div>


        						<input className="contact-button" type="submit" value="Send Message" />
        						<input className="contact-button" type="reset" value="Reset" />

        				</form>
                    </div>
                </div>
        </div>
        </section>
    );
}
export default Contact;
