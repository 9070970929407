import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {

    return(
        <section className="footer snap">
            <p>Ver 0.2.0 | 2021</p>
            <div className="icons">
            <a href="https://github.com/MathewJenik/">
                <FontAwesomeIcon icon={faGithubSquare} />
            </a>
            <a href="https://www.linkedin.com/in/mathew-jenik-0895b8198/?originalSubdomain=au">
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
            </div>
        </section>
    );
}
export default Footer;
