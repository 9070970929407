import Project from "../../Components/Project/Project";
import ThreeDBookCover from "../../Images/Projects/3d-book-animation/cover.webp";


function Electronics() {

    return(
        <div className="page-content">
            <h1>Electrical Engineering and Design</h1>
            <Project name={'Electric Drill Upgrade'} tags={''}/> 
            <Project name={'AI Crypto Trading Website (WIP)'} tags={'JS,Nueral Networks'} desc={'A website that keeps track of crypto prices and trains a nueral network on when to buy and sell crypto.'} img={ThreeDBookCover} />  
        </div>
    );
}

export default Electronics