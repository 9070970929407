import './Tag.css';

function Tag(props) {

    return(
        <div className="tag">
            <h4>{props.type}</h4>
        </div>
    );
}

export default Tag;
