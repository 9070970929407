import "./ShufflingText.css"
import { useState, useEffect } from "react";

String.prototype.replaceAt = function (index, char) {
    let a = this.split("");
    a[index] = char;
    return a.join("");
}
function ShufflingText(props) {

    
    const textList = "aabcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ";
    let originalText = props.original;
    let tagType = props.tag;
    let randomisedText = "";
    
    const [text, setText] = useState("");

    function randomiseStartingText() {
        let result = "";

        for (let i = 0; i < originalText.length; i++) {
            result += textList[Math.floor(Math.random() * textList.length)];
        }

        return result;
    }
    
    function processLetter(i,j) {
        if (i < originalText.length) {
            setTimeout(() => {
                if (j < textList.length) {
                    // now shuffle the letter
                    let result = randomisedText;
                    result = result.replaceAt(i, textList[j]);
                    console.log("replaced:", result);
                    randomisedText = result;
                    setText(randomisedText);
                    console.log("Desired Letter: ", originalText[i]);
                    console.log("Current Letter: ", textList[j]);
                    // if the current letters match the desired, jump to next
                    if (textList[j] == originalText[i]) {    
                        j = 0;
                        i++;
                        processLetter(i, j);
                    } else {
                        processLetter(i, j+1);
                    }
                } else {
                    j = 0;
                    i++;
                    processLetter(i, j);
                }

                
            }, 10);
        }
    }

    function shuffleTwo() {    
        processLetter(0, 0);
    }


    /*
    * Shuffle the whole word, after each set of iterations
    * the next letter is revealed
    */
    function shuffleSort(i) {
        if (i < originalText.length) {
            setTimeout(() => {
                let reducedIter = Math.floor(i);
                let result = randomisedText;
                console.log("REDUCED: ", reducedIter);
                // randomise the whole word
                console.log("ORIGLENG", originalText.length);
                for (let j = 0; j < originalText.length; j++) {

                    // now check if the number of iterations passed is further than the 
                    // position off the letter + some spacing
                    if (reducedIter < j) {
                        result = result.replaceAt(j, textList[(Math.random() * textList.length)]);
                        let random = Math.floor(Math.random() * textList.length)
                        result = result.replaceAt(j, textList[random]);
                        } else {
                        result = result.replaceAt(j, originalText[j]);
                    }
                    console.log("RESULT: ", result);
                }
                //let random = Math.floor(Math.random() * textList.length)
                //result = result.replaceAt(0, textList[random]);
                // set the word to match the randomised version
                setText(result);
                shuffleSort(i+1);

            }, 100);
        }
    }


    
    useEffect(()=>{
        if (props.on == true) {
        randomisedText = randomiseStartingText();
        setText(randomisedText);
        //shuffleTwo();
        shuffleSort(0);
        } else {
            setText(props.original);
        }
    }, [])

    //setTimeout(function() { your_func(); }, 5000);

    return(
        <div onLoad={shuffleTwo} className="shufflingText">
            {tagType == "p" && (
                <p>{text}</p>
            ) || tagType == "h1" && (
                <h1>{text}</h1>
            ) || tagType == "h2" && (
                <h2>{text}</h2>
            )}
        </div>
    );  
    
}
export default ShufflingText;