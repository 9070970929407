import { render } from '@testing-library/react';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import './ProjectPreview.css';

// width="400em" height="340em"
function ProjectPreview(props) {


    function renderTags() {
        let tags = [];
        var splitTags = props.tags.split(",");
        console.log(splitTags);
        for (let i = 0; i < splitTags.length; i++) {
            tags.push(<p className='tag'>{splitTags[i]}</p>);
        }
        return tags;
    }

    return (
        <div className="ProjectPreview-container">
            <div className="ProjectPreview-item">
                <img src={props.img} /> 
                <div className="ProjectPreview-text-container">
                    <h3>{props.name}</h3>
                    <p>
                        {props.desc}
                    </p>
                    <div className='ProjectPreview-tags'>
                        {renderTags()}
                    </div>
                </div>
            </div>

        </div>


        
    );
}

export default ProjectPreview;