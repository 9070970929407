import React, { useState } from 'react';
import './WorkExperienceCard.css';

const WorkExperienceCard = ({ company, position, startDate, endDate, description }) => {
  const [expanded, setExpanded] = useState(false);
  const [closing, setClosing] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);

  };

  return (
    <div className={`work-experience-card${expanded ? '-expanded' : ''}`}> 
        {!expanded ? (
            <div className="work-experience-header" onClick={toggleExpand}>
                <h3>{position} at {company}</h3>
                <span>{startDate} - {endDate}</span>
            </div>

        ) : (
            <div className="work-experience-header-active" onClick={toggleExpand}>
                <h3>{position} at {company}</h3>
                <span>{startDate} - {endDate}</span>
            </div>
        )}
      
            <div className={`work-experience-details${closing ? '-closing' : ''}`}>
                <br></br>
                <p>{description}</p>
                <br></br>
                {/* Add more details here if needed */}
            </div>
        
    </div>
  );
};

export default WorkExperienceCard;
