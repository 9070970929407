import "../LinkBar/LinkBar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';


function LinkBar() {
    return(
        <div className="link-bar">
            <a href="https://github.com/MathewJenik/">
                <FontAwesomeIcon icon={faGithubSquare} />
            </a>
            <a href="https://www.linkedin.com/in/mathew-jenik-0895b8198/?originalSubdomain=au">
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <div className="link-bar-line">
            </div>
        </div>
    );
}

export default LinkBar;