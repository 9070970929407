import Tag from '../../Components/Tag/Tag.js';
import './ProjectTab.css';

function ProjectTab(props) {

    function renderData() {
        let tags = [];
        if (props.tagString != undefined) {
            var splitTags = props.tagString.split(",");
            console.log(splitTags);
            for (let i = 0; i < splitTags.length; i++) {
                tags.push(<Tag type={splitTags[i]} />);
            }
        } else {
            for (let i = 0; i < props.tags.length; i++) {
                tags.push(<Tag type={props.tags[i]} />);
            }
        }
        return tags;
    }

    let height = '140px;'
    let width = 'auto';
    if (props.wh != null) {
        height = props.wh;
        width = props.wh;
    }

    function renderLinks() {
        
        
        if (props.links != null) {
            <a href={props.links[1]}>{props.links[0]}</a>

            let links = [];
            let splitLinks = props.links;//.split(",");
            
            for (let i = 0; i < splitLinks.length; i++) {
                links.push(<a href={props.links[i].url}>{props.links[i].name}</a>);
                if (i < splitLinks.length-1) {
                    //links.push(<br />);
                }
            }
            console.log(links);
            return links;
        }
   }

    return(
        <div className="project snap">
            <img className="scale-down-image" alt="" src={props.img} height={height} width={width}/>
            <div className="project-contents">
                <h3>{props.name}</h3>
                <div className="tags">{renderData()}</div>
                <p>{props.desc}</p>
                <div className="links">
                    {renderLinks()}
                </div>
            </div>
        </div>
    );

}
export default ProjectTab;


