import Service from '../../Components/Service/Service.js';
import './Services.css';

function Services() {

    return(
        <div className="services">
            <div className="left-container">
                <div className="hire-container">
                    <h3>Hire Me!</h3>
                    <p>If you are interested in hiring me for any of my services, or any custom work you might require to be complete, dont hesitate to contact me!</p>
                </div>
                <div className="pms-container">
                    <h3>Project Management System</h3>
                    <img />
                    <p>Use my custom made Project Management System to track any project needs you have. This project management system is also used to keep you up to date on any projects i am completing for you.</p>
                    <p><a href="http://proj-man-sys.herokuapp.com">Project Management System</a></p>
                    <p>The project Management System is continually updated and updated with new features and improvements. </p>
                </div>
            </div>
            <div className="services-container">
                <Service name={'Web Development'} desc={'Using HTML, JavaScript, CSS, PHP or any other frameworks such as React or ASP.NET i can develop and design websites for your needs and uses, in a timely manner.'}/>
                <Service name={'React Development'} desc={'Using React/ReactNative i can create applications or web apps to your requirements.'}/>
                <Service name={'Software Development'} desc={'Using technologies such as Windows Forms, JavaFX and many other desktop applicaion development technologies i can create a desktop application for your needs.'}/>
                <Service name={'Mobile App Development'} desc={'Using Java for Android development, C# or Swift for IOS development, or ReactNative for both iOS and Android, i can create an mobile applicaiton that meets your requirements.'}/>
                <Service name={'Script Development'} desc={'Using different scripting languages or programming languages, such as Python, C++, C# i can create scripts that meet your requirements and needs.'}/>
            </div>

        </div>
    );
}
export default Services;
