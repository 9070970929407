import './Service.css';

function Service(props) {

    return (
        <div className="service">
            <h3>{props.name}</h3>
            <p>{props.desc}</p>
        </div>
    );
}
export default Service;
